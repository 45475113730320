import React from "react"
import { Box, Flex, Text } from "@sqymagma/elements"
import {VirusIco} from '../../IconSystem'

export default ({children}) => {
  return (
    <Flex width={1} textAlign="center" my="m" bg="ui03" borderRadius="4px" overflow="hidden">
        <Flex bg="brand05" alignItems="center" justifyItems="center" p={["xs","s"]}>
            <VirusIco height={["24px","45px"]} fill="inverse01"/>
        </Flex>
        <Box>
            <Text as="p" textStyle="l" p={["xs","s"]} pl={["s", "m"]} textAlign="left">
                {children}
            </Text>
        </Box>
    </Flex>
  )
}
