import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día -18",
  week: "Semana -2",
  day: "24",
  month: "feb",
  monthNumber: "02",
  date: "2020-02-24",
  path: "/cronologia/semana-previa-2#dia-24-feb",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModImage src='/svg/24_feb_italia.svg' alt='Casos confirmados en Italia'/>
        <ModText>
               Durante este fin de semana se han registrado 132 casos en la zona
              norte de <strong>Italia</strong>, siendo las regiones más
              afectadas Lombardía, Veneto y Emilia Romagna. Con estas cifras, se
              coloca como segundo país con más casos confirmados de COVID-19 a
              nivel mundial.
        </ModText>
        <ModText>
              Las autoridades de <strong>Salud Pública de Italia</strong> han
              puesto en marcha <strong>medidas de contención</strong> en las áreas
              y municipios en las que al menos una persona ha resultado positiva
              en una prueba frente a COVID-19.
        </ModText>
        <ModDotList
          item1="11 municipios aislados y cuarentenados
          por decreto. Región de Lombardía (10): Bertonico;
          Casalpusterlengo; Castelgerundo; Castiglione D'Adda; Codogno;
          Fombio; Maleo; San Fiorano; Somaglia; Terranova dei Passerini.
          Región del Véneto (1): Vo' Euganeo."
          item2="6 regiones con restricción de
          actividades públicas (culturales, recreativas, deportivas,
          religiosas y escolares): Lombardía Véneto, Piamonte, Liguria,
          Trentino-Alto Adige, Friuli Venezia Giulia y Emilia-Romaña."
        />
        <ModDashedLine/>
        <ModDataEu fecha={frontmatter.date}/>
      </ContentLeft>

      <ContentRight>
        <ModText>
              En <strong>España</strong> se ha realizado una prueba de detección de COVID-19 en Tenerife, a una persona procedente de Lombardía, que resultó positiva. El caso está pendiente de confirmación hoy en el centro Nacional de Microbiología.
        </ModText>
        <ModImage src='/svg/24-02-canarias.svg' alt='posible caso de coronavirus detectado en Tenerife'/>
        
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
