import React from "react"
import { Text } from '@sqymagma/elements'
import { theme } from '../../../utils/themeGet'

const ReferenceRow = ({ link, name }) => {
  return (
    link && name ? (
      <tr>
            <Text
                as="td"
                px="s" py="xs"
                css={`{border: 1px solid ${theme('color.ui03')}}`}
            >
                <img 
                    src="/images/svg/icon-file-grey.svg" alt="file" 
                    css={`{
                       width: 16px;
                       vertical-align: middle;
                       margin-right: 5px;
                       display: inline
                    }`}/>
                    <Text 
                        as="a" 
                        href={link} target="_blank" 
 rel="noopener noreferrer" 
                        textStyle="table-row"
                        css={`{

                            &:hover{
                                color:${theme("color.brand05")};
                                transition: color ease-in-out .3s;
                            }
                        }`}
                    >
                        {name}
                    </Text>
            </Text>
    </tr>
    ) : "Error"
  )
}

export default ReferenceRow