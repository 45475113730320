import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día -15",
  week: "Semana -2",
  day: "27",
  month: "feb",
  monthNumber: "02",
  date: "2020-02-27",
  path: "/cronologia/semana-previa-2#dia-27-feb",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/27_feb_italia.svg"
          alt="mapa la extensión del covid-19 en Italia"
          small={false}
          inverted={true}
        >
          En <strong>Italia</strong> se han notificado un total de 322 casos.
          Las regiones más afectadas siguen siendo Lombardía (240), Véneto (43)
          y Emilia Romaña (26). También se han registrado casos de COVID-19 en
          las regiones de Piamonte, Sicilia, Toscana, Liguria, Trentino Alto
          Adige, Marche y Lacio. El número de fallecidos asciende a 11 personas.
        </ModTwoCols>
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Grecia confirma su primer caso positivo
        </ModCovidHighlight>
        <ModCovidHighlight>
          Dinamarca confirma su primer caso positivo
        </ModCovidHighlight>
        <ModCovidHighlight>
          Georgia confirma su primer caso positivo
        </ModCovidHighlight>
        <ModCovidHighlight>
          Noruega confirma su primer caso positivo
        </ModCovidHighlight>
        <ModCovidHighlight>
          Rumanía confirma su primer caso positivo
        </ModCovidHighlight>
        <ModCovidHighlight>
          Macedonia confirma su primer caso positivo
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En esta semana se han detectado un total de 16 casos en{" "}
          <strong>España</strong>. Once de ellos tienen relación epidemiológica
          con el brote de Italia, y el resto permanece en investigación.
        </ModText>
        <ModText>
          La Comunidad Valenciana confirma a última hora la detección de otros
          seis casos que se han hospitalizado. Cuatro de ellos viajaron a Milán
          y dos de ellos estuvieron en contacto con un paciente que también
          había viajado a la zona de riesgo en Italia.
        </ModText>
        <ModImage
          src="/images/svg/02_abr_investigacion.svg"
          alt="estudio microbiología por el Centro Nacional de Microbiología"
        />

        <ModText>
          Estos nuevos casos detectados están pendientes de confirmar por{" "}
          <strong>Centro Nacional de Microbiología</strong>, en Majadahona
          (Madrid), donde se están centralizando los análisis de todas las
          muestras recogidas.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
