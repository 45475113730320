import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -16",
  week: "Semana -2",
  day: "26",
  month: "feb",
  monthNumber: "02",
  date: "2020-02-26",
  path: "/cronologia/semana-previa-2#dia-26-feb",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModTwoCols
          src="/images/svg/26_feb_italia.svg"
          alt="Casos confirmados en Italia por coronavirus"
          small={false}
          inverted={true}
        >
          En <strong>Italia</strong> se han notificado un total de 322 casos.
          Las regiones más afectadas siguen siendo Lombardía (240), Véneto (43)
          y Emilia Romaña (26). También se han registrado casos de COVID-19 en
          las regiones de Piamonte, Sicilia, Toscana, Liguria, Trentino Alto
          Adige, Marche y Lacio. El número de fallecidos asciende a 11 personas.
        </ModTwoCols>

        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Austria declara suprimer caso confirmado de SARS-Cov-2
        </ModCovidHighlight>
        <ModCovidHighlight>
          Suiza declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Croacia declara su primer caso confirmado
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, cabe reseñar que el caso que estaba
          pendiente de confirmación en Tenerife ha resultado positivo. Asimismo,
          han resultado contagiadas tres personas más que habían estado en
          contacto directo con dicho paciente, lo que eleva a cinco el número de
          casos detectados en Canarias.
        </ModText>
        <ModImage
          src="/images/svg/26-02-canarias.svg"
          alt="caso de coronavirus pendiente de confirmar en Tenerife da positivo"
        />
        <ModCovidHighlight>Andalucía: primer caso confirmado</ModCovidHighlight>
        <ModText>
          La Dirección General de Salud Pública de la Consejería de Salud y
          Familias de Andalucía confirma el primer caso detectado en la
          comunidad autónoma en un paciente ingresado en el Hospital
          Universitario Virgen del Rocío (Málaga). Se trata de un paciente de 62
          años que no había viajado con anterioridad a Italia.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
