import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";


export const frontmatter = {
  title: "Día -19",
  week: "Semana -2",
  day: "20",
  month: "feb",
  monthNumber: "02",
  date: "2020-02-20",
  path: "/cronologia/semana-previa-2#dia-20-feb",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDashedLine/>
        <ModDataEu fecha={frontmatter.date}/>
      </ContentLeft>

      <ContentRight>
        <ModText>
        El <strong>27 de enero</strong> de 2020 se confirmó el primer caso
              de COVID-19 en Europa (Alemania), en un hombre de 33 años en
              Baviera, que en los días anteriores había tenido contacto con un
              caso de COVID-19 procedente en Shanghai. Hasta el momento, se han
              detectado 14 casos asociados a este agrupamiento en Alemania, y
              uno en España, en la Isla de La Gomera.
        </ModText>
        <ModText>
        El <strong>31 de enero</strong> de 2020 fue la fecha en la que se
              confirmó dicho caso en la isla de La Gomera, en un ciudadano
              alemán, y supone el primer caso positivo de coronavirus COVID-19
              en nuestro país.
        </ModText>
        <ModImage src='/svg/20_feb_canarias.svg' alt='Casos confirmados en Canarias'/>
        
        <ModText>
              El <strong>9 de febrero</strong> se confirmó el segundo caso en un
              residente de Mallorca, que estuvo en contacto con otro caso
              detectado en Reino Unido, procedente del agrupamiento Contamines.
              En la actualidad, ambos casos están cerrados.
        </ModText>
        <ModText>
              El <strong>Ministerio de Sanidad</strong> ha hecho público un <InlineLink link='https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/Evaluacion_de_riesgo_y_medidas.pdf'>
                documento
              </InlineLink> donde recoge la Evaluación de riesgo para España.
        </ModText>
        <ModReferenceList title='Guías y documentos publicados'>
          <ReferenceRow
            link='https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/Evaluacion_de_riesgo_y_medidas.pdf'
            name='Evaluación de riesgo para España'
          />
        </ModReferenceList>
        
        
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
