
import React from "react"
import { Box } from '@sqymagma/elements'


export default({src, alt=""}) => {
  return (
    <Box width={1} textAlign="center" my="m" mh="s">
      <img src={src} alt={alt} />
    </Box>
  )
}

