import React from "react"
import { Text } from '@sqymagma/elements'
import { theme } from '../../../utils/themeGet'

export default({ children, link }) => {
  return (

    <Text 
        as="a" 
        href={link} target="_blank" 
 rel="noopener noreferrer" 
        color="brand02"
        fontWeight="600"
        css={`{
            cursor:pointer,
            &:hover{
                color:${theme("color.brand01")};
                transition: color ease-in-out .3s;
            }
        }`}
    >
        { children }
    </Text>
  )
}
