import React from "react";
import { graphql } from "gatsby";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day20Feb from "./dia-20-feb";
import Day24Feb from "./dia-24-feb";
import Day25Feb from "./dia-25-feb";
import Day26Feb from "./dia-26-feb";
import Day27Feb from "./dia-27-feb";
import Day28Feb from "./dia-28-feb";

import SecWeekly from "../../../components/Chronology/Modules/SecWeekly";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <SecWeekly
        period="20 Febrero - 28 Febrero"
        week="Semana -2 de confinamiento"
      ></SecWeekly>
      <Day28Feb {...props} />
      <Day27Feb {...props} />
      <Day26Feb {...props} />
      <Day25Feb {...props} />
      <Day24Feb {...props} />
      <Day20Feb {...props} />
    </LayoutWeek>
  );
};

export const pageQuery = graphql`
  query {
    allSitePage(
      filter: { id: { regex: "/semana-[0-9]+/$/" } }
      sort: { fields: path, order: DESC }
    ) {
      edges {
        node {
          path
        }
      }
    }
  }
`;
export default Week;
