import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";

export const frontmatter = {
  title: "Día -14",
  week: "Semana -2",
  day: "28",
  month: "feb",
  monthNumber: "02",
  date: "2020-02-28",
  path: "/cronologia/semana-previa-2#dia-28-feb",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="it"
          customText="Casos Confirmados"
          customNumber="+62,5%"
        />
        <ModText>
          Actualmente se considera que{" "}
          <strong>existe transmisión comunitaria en Italia,</strong> donde el
          número de casos asciende a 650. Las regiones más afectadas siguen
          siendo Lombardía (403), Véneto (111) y Emilia Romaña (97). También se
          han registrado casos de COVID-19 en las regiones de Piamonte, Sicilia,
          Toscana, Liguria, Trentino Alto Adige, Marche y Lacio. El número de
          fallecidos asciende a 17 personas.
        </ModText>
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Países Bajos declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          San Marino declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Bielorusia declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Estonia declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Lituania declara su primer caso confirmado
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 32
          casos: 6 en las Islas Canarias, 8 en Comunidad Valenciana, 6 en
          Andalucía, 3 en Cataluña, 5 en Madrid, 2 en Castilla y León, 1 en
          Aragón y 1 en Baleares.
        </ModText>
        <ModImage
          src="/images/svg/28-02-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModImage
          src="/images/svg/datafact/28-02-datafact-cv.svg"
          alt="Tabla confirmados Comunidad Valenciana"
        />

        <ModCovidHighlight>
          Castilla y León: primeros casos confirmados
        </ModCovidHighlight>
        <ModCovidHighlight>Baleares: primer caso confirmado</ModCovidHighlight>
        <ModCovidHighlight>Aragón: Primer caso confirmado</ModCovidHighlight>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
