import React from "react"
    import { Box, Text } from "@sqymagma/elements"
    import { theme } from '../../../utils/themeGet'

    const ModReferenceList = ({ children, title }) => {
    return (
        <Box my="m" width={1}> 
            <Box  
                as="table"
                width={1}
                css={`{
                    border-collapse: collapse;
                    border-spacing: 2px;
                }`}
            >
                <thead>
                    <tr>
                        <Text
                            as="th"
                            px="s" py="xs"
                            color="brand02"
                            textAlign="left"
                            textStyle="l-semi"
                            caps
                            css={`{border: 1px solid ${theme('color.ui03')}}`}
                        > 
                        {title}
                        </Text>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </Box>
        </Box>
    )
    }

    export default ModReferenceList

