import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -17",
  week: "Semana -2",
  day: "25",
  month: "feb",
  monthNumber: "02",
  date: "2020-02-25",
  path: "/cronologia/semana-previa-2#dia-25-feb",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModText>
              En <strong>Italia</strong> se han notificado 229 casos. Las zonas
              del norte más afectadas son Lombardía, Véneto y Emilia-Romaña, con
              172, 33 y 18 casos, respectivamente. Han fallecido 7 personas.
        </ModText>
        <ModImage src='/svg/25_feb_italia.svg' alt='Casos confirmados en Italia'/>
        <ModDashedLine/>
        <ModDataEu fecha={frontmatter.date}/>
      </ContentLeft>

      <ContentRight>
        <ModText>
              En <strong>España</strong> se han detectado otros 4 casos en
              Madrid (2), Castellón (1) y Barcelona (1), todos ellos con <strong>vínculo epidemiológico con las zonas con transmisión
                comunitaria de Italia</strong >. Los 8 casos detectados en total están pendientes de
              confirmación en el centro Nacional de Microbiología.
        </ModText>
        <ModCovidHighlight>
        Madrid: primeros casos detectados
        </ModCovidHighlight>
        <ModCovidHighlight>
        Comunidad Valenciana: primer caso detectado
        </ModCovidHighlight>
        <ModCovidHighlight>
        Cataluña: primer caso detectado
        </ModCovidHighlight>
        
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
