import React from "react"
import { Box, Text } from "@sqymagma/elements"
import { theme } from "../../../utils/themeGet"

export default (items) => {

  return (
    <Box width={1} my="m">
        {Object.values(items) 
            ?   <Text textStyle="bodyText" as="ul">
                    {Object.values(items).map((item,idx) => (
                        <Text 
                            textStyle="bodyText" as="li" mb="s" key={idx}
                            css={`{
                                &:before {
                                    content: "";
                                    border-radius: 50%;
                                    background-color: ${theme("color.brand02")};
                                    height: .5rem;
                                    width: .5rem;
                                    display: inline-flex;
                                    margin-right: .5rem;
                                }
                            }`}
                        >   
                            {item}
                        </Text>
                    ))}
                </Text>
            : "Error"
        }
    </Box>
  )
}


